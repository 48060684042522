import React from "react"
import Sidebar from '../../components/sidebar'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import lgHausys2 from "../../assets/img/lghausys2.jpg"

const produitsInterieurPage = () => (
  <Layout>
    <SEO title="chargeur sans fil intégré" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div class="grid grid-cols-1 gap-2 md:col-span-3 md:my-5 mx-3"> {/** first element */}
             <h1 class="text-2xl font-bold mb-8 divider">PRODUITS D’INTÉRIEUR ET COLLECTIONS</h1>
            <img class="mb-0 mx-auto w-full" src={lgHausys2} alt="LG Hausys" />
            <h1 class="text-2xl text-center font-bold mb-2 divider">PRODUITS D'INTÉRIEUR ET COLLECTIONS</h1>
            <p class="text-justify mt-3 text-center">
            Quand il s’agit d’aménagement, les couleurs sont décisives. Tout est là, vous n’avez plus qu’à choisir.<br />
            Téléchargez le dépliant des nouvelles couleurs Velvet <a href="http://himacs.eu/pdf/HI-MACS_A4_Promotional-Flyer_FR.pdf" class="text-red-700">ici</a>.
           </p>
       </div>

       <div>
         <Sidebar />
       </div>
    </div>
  </Layout>
  )
 
  export default produitsInterieurPage
